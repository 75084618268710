import React from 'react';
import bg from '../../resources/img/bg-bienvenida.svg';
import logo from '../../resources/img/login/pulso.svg';
import './styles.scss';
let backgroundStyles = `#root::after{background-image: url(${bg})}`;

export default function Loading() {
	return (
		<>
			<style>{backgroundStyles}</style>
			<div className='loader'>
				{/* <div className='outer' />
				<div className='middle' /> */}
				<img src={logo} alt='' />
			</div>
		</>
	);
}
