import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from '../components/Loading';

const Login = lazy(() => import('../views/Login'));
const Instrucciones = lazy(() => import('../views/Instrucciones'));
const Home = lazy(() => import('../views/Home'));
const LoginAdmin = lazy(() => import('../views/Administracion/Login'));
const Dashboard = lazy(() => import('../views/Administracion/Dashboard'));
const Usuarios = lazy(() => import('../views/Administracion/Usuarios'));
const UsuariosCrear = lazy(() => import('../views/Administracion/Usuarios/Crear'));
const UsuariosActualizar = lazy(() => import('../views/Administracion/Usuarios/Actualizar'));
const Comparativa = lazy(() => import('../views/Administracion/Comparativa'));
const Encuestas = lazy(() => import('../views/Administracion/Encuestas'));
const Lideres = lazy(() => import('../views/Administracion/Lideres'));

export default function App() {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route exact path='/' element={<Login />} />
					<Route exact path='/instrucciones' element={<Instrucciones />} />
					<Route exact path='/home' element={<Home />} />
					<Route exact path='/admin/login' element={<LoginAdmin />} />
					<Route exact path='/admin/dashboard' element={<Dashboard />} />
					<Route exact path='/admin/usuarios' element={<Usuarios />} />
					<Route exact path='/admin/usuarios/crear' element={<UsuariosCrear />} />
					<Route exact path='/admin/usuarios/:id/actualizar' element={<UsuariosActualizar />} />
					<Route exact path='/admin/reportes/comparativa' element={<Comparativa />} />
					<Route exact path='/admin/reportes/encuestas' element={<Encuestas />} />
					<Route exact path='/admin/reportes/lideres' element={<Lideres />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}
